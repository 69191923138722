<!--修改手机号暂不支持-->
<template>
    <el-dialog
        title="修改手机号"
        :visible.sync="dialogTableVisible"
        :show-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="dialog_class editTel-dialog"
    >
        <!-- 登录表单区域  title="请绑定个人信息"-->
        <span>简单三步，立即完成</span>
        <el-steps
            :active="stepIndex"
            class="step"
            align-center
            finish-status="success"
        >
            <el-step title="步骤 1" description="验证身份"></el-step>
            <el-step title="步骤 2" description="填写新手机"></el-step>
            <el-step title="步骤 3" description="修改完成"></el-step>
        </el-steps>
        <el-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            class="login_form"
        >
            <!-- 手机号 -->
            <el-form
                ref="loginFormRef1"
                :inline="true"
                :model="loginForm"
                label-position="top"
                label-width="90px"
                :rules="loginFormRules"
            >
                <el-form-item :label="label[labelindex]" prop="userTel">
                    <el-input
                        v-model="loginForm.userTel"
                        :disabled="!isNext"
                        class="input-with-select"
                    >
                        <el-select
                            v-model="value"
                            slot="prepend"
                            :disabled="!isNext"
                            placeholder="+86"
                            class="select"
                        >
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="短信验证码"
                    prop="noteCode"
                    style="margin-right: 0"
                >
                    <phone-code
                        v-model="loginForm.noteCode"
                        :phone="loginForm.userTel"
                        @verifyPhone="verifyLoginForm1"
                    ></phone-code>
                </el-form-item>
                <el-row type="flex" justify="end" style="margin-top: 10px">
                    <el-button
                        type="primary"
                        plain
                        :disabled="!loginForm.noteCode"
                        v-if="!isNext"
                        @click="nextStep"
                    >
                        下一步
                    </el-button>
                    <el-button
                        type="primary"
                        plain
                        v-if="isNext"
                        :disabled="!loginForm.noteCode"
                        @click="success"
                    >
                        完成
                    </el-button>
                </el-row>
            </el-form>
        </el-form>
    </el-dialog>
</template>

<script>
import PhoneCode from "@/components/common/PhoneCode";
import md5 from "js-md5";
import { updateUserInfo, userRegister } from "@/api";
import { checkCodes } from "@/api/login";
import { phoneReg } from "@/utils/config";

export default {
    name: "editTelDialog",
    data() {
        return {
            msg: "",
            id: 0,
            dialogTableVisible: false,
            olduserTel: "",
            isSuccess: false,
            isNext: false,
            isCode: true,
            label: ["原手机号", "新手机号"],
            labelindex: 0,
            stepIndex: 1,
            options: [
                {
                    value: "HTML",
                    label: "HTML",
                },
                {
                    value: "CSS",
                    label: "CSS",
                },
                {
                    value: "JavaScript",
                    label: "JavaScript",
                },
            ],
            value: [],
            // 这是登录表单的数据绑定对象
            loginForm: {
                userTel: "",
                noteCode: "",
            },

            // 这是表单的验证规则对象
            loginFormRules: {
                userTel: [
                    { message: "请输入手机号码", trigger: "blur" },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
            },
        };
    },
    components: {
        PhoneCode,
    },
    mounted() {},
    methods: {
        init(userTel, id) {
            this.dialogTableVisible = true;
            this.isNext = false;
            this.labelindex = 0;
            this.stepIndex = 1;
            // this.olduserTel = userTel
            this.loginForm.userTel = userTel;
            this.id = id;
        },
        // 点击重置按钮，重置登录表单
        resetLoginForm() {
            // console.log(this);
            this.$refs.loginFormRef.resetFields();
            this.$refs.loginFormRef1.resetFields();
        },
        nextStep() {
            this.$refs.loginFormRef1.validate(async (valid) => {
                if (valid) {
                    if (this.loginForm.noteCode) {
                        this.checkCode().then(async (result) => {
                            // console.log(result)
                            if (result.data.status == true) {
                                this.isNext = true;
                                this.labelindex = 1;
                                this.stepIndex = 2;
                                this.loginForm.userTel = "";
                                this.loginForm.noteCode = "";
                            }
                        });
                    }
                }
            });
        },
        success() {
            var that = this;
            this.$refs.loginFormRef1.validate(async (valid) => {
                if (valid) {
                    if (this.loginForm.noteCode) {
                        this.checkCode().then(async (result) => {
                            console.log(result);
                            if (result.data.status == true) {
                                var data = {};
                                data.id = that.id;
                                data.phone = that.loginForm.userTel;
                                const { data: res } = await updateUserInfo(
                                    data,
                                );
                                console.log("UpdateUserName", res);
                                if (res.meta.status !== 201) {
                                    var msg = "";
                                    if (res.meta.msg.Message) {
                                        msg = res.meta.msg.Message;
                                    } else {
                                        msg = res.meta.msg;
                                    }

                                    return that.$message.error(msg);
                                } else {
                                    that.$message.success("修改手机号成功");
                                    that.labelindex = 2;
                                    that.stepIndex = 3;
                                    that.loginForm.userTel = "";
                                    that.loginForm.noteCode = "";
                                    that.$store.commit(
                                        "ship/editUserphone",
                                        that.loginForm.userTel,
                                    );
                                    that.$emit("refreshDataList");
                                    that.dialogTableVisible = false;
                                }
                                // this.isNext = true
                            }
                        });
                    }
                }
            });
        },
        updateTel() {
            this.$refs.loginFormRef1.validate(async (valid) => {
                // console.log(valid)
                const { userTel, username, password } = this.loginForm;
                var registerData = {
                    phone: userTel,
                    // companyName: companyName,
                    // duty: duty,
                    openid: this.$store.state.user.openId,
                    name: username,
                    pwd: md5(password),
                    userId: this.$store.state.user.userId,
                };
                if (valid) {
                    if (this.loginForm.noteCode) {
                        this.checkCode().then(async (result) => {
                            console.log(result);
                            if (result.data.status) {
                                const { data: res } = await userRegister(
                                    registerData,
                                );
                                console.log("register", res);
                                if (res.meta.status !== 201) {
                                    var msg = "";
                                    if (res.meta.msg.Message) {
                                        msg = res.meta.msg.Message;
                                    } else {
                                        msg = res.meta.msg;
                                    }

                                    return this.$message.error(msg);
                                } else {
                                    this.saveToken2(res.data.token);
                                    this.$message.success("注册成功");
                                    this.dialogTableVisible = false;
                                }
                            } else {
                                this.$message.warning("验证码不正确");
                            }
                        });
                    } else {
                        this.$message.warning("请输入验证码");
                    }
                }
            });
        },
        async checkCode() {
            const params = new URLSearchParams();
            params.append("phone", this.phone);
            params.append("code", this.loginForm.noteCode);
            // return await this.$axios2.post('/textmessage/checkCode', params)
            return await checkCodes(params);
        },
        verifyLoginForm1() {
            this.$refs.loginFormRef1.validate();
        },
    },
};
</script>

<style lang="stylus" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100vh;
  border-radius: 8px;
}

.editTel-dialog {
  &.el-dialog__wrapper {
    width: 50%;
    min-width: 40rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .el-dialog__body {
    padding: 0px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}

.login_form {
  // position: absolute;
  // bottom: 0;
  margin-top: 20px;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
  display: flex;
  // margin-top: 10px;
  flex-direction: column;
  justify-content: center;
}

.btns {
  // display: flex;
  // justify-content: flex-start;
  span {
    padding: 0 20px;
    text-align: left;
  }
}

.btn1 {
  transform: translate(300%);
}

.top_div {
  background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.canvas_div {
  height: 100vh;
  width: 100%;
}

.dialog_class {
  // display: fixed;
  border-radius: 16rpx;
  font-weight: 600;
  // top: 20%;
  // left: 30%;
}

// .el-step__icon {
//   width: 40px;
//   height: 40px;
// }

.step {
  margin-top: 20px;
}

.input-with-select {
  width: 550px;
  margin-bottom: 20px;
}

.select {
  width: 120px;
}
</style>
