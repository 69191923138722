<template>
    <div class="bac_f">
        <!-- 面包屑导航 -->
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
      <el-breadcrumb-item>账户设置</el-breadcrumb-item>
    </el-breadcrumb> -->
        <div class="bac">
            <el-card style="width: 100%;">
                <div slot="header" class="header">
                    <span>账户设置</span>
                </div>
                <div class="mima">
                    <span>账户信息</span>
                </div>
                <div class="text_item_wrap">
                    <div class="text_item" v-if="changeNameFlag == 0">
                        <span class="text_item_label">用户名</span>
                        <span>{{ oldUserInfo.userName }}</span>
                        <i
                            class="el-icon-edit"
                            @click="toggleItemFlag('userName', 0)"
                        ></i>
                    </div>
                    <div class="text_item" v-if="changeNameFlag == 1">
                        <span class="text_item_label">用户名</span>
                        <el-input
                            size="small"
                            placeholder="请输入新用户名"
                            v-model="newName"
                            style="width: 200px"
                            maxlength="20"
                            show-word-limit
                            ref="userNameInput"
                            @blur="toggleItemFlag('userName', 1)"
                            @change="changeName"
                        ></el-input>
                        <el-button size="small" type="primary" plain>
                            确 定
                        </el-button>
                    </div>
                    <div class="text_item">
                        <span class="text_item_label">手机号码</span>
                        <span>{{ oldUserInfo.userTel }}</span>
                        <!-- 暂时关闭手机号修改功能 -->
                        <!-- <i class="el-icon-edit" @click="editInfo(oldUserInfo.userTel)"></i> -->
                    </div>
                    <div class="text_item" v-if="changeQQFlag == 0">
                        <span class="text_item_label">QQ号</span>
                        <span>{{ oldUserInfo.qqNumber }}</span>
                        <i
                            class="el-icon-edit"
                            @click="toggleItemFlag('qqNumber', 0)"
                        ></i>
                    </div>
                    <div class="text_item" v-if="changeQQFlag == 1">
                        <span class="text_item_label">QQ号</span>
                        <el-input
                            size="small"
                            placeholder="请输入新QQ号"
                            v-model="newQQNum"
                            style="width: 200px"
                            ref="qqNumInput"
                            @blur="toggleItemFlag('qqNumber', 1)"
                            @change="editQQNumber"
                        ></el-input>
                        <el-button
                            size="small"
                            plain
                            type="primary"
                            @click="editQQNumber"
                        >
                            确 定
                        </el-button>
                    </div>
                    <div
                        class="text_item"
                        v-if="$store.state.user.loginId != 'personal'"
                    >
                        <span class="text_item_label">公司名称</span>
                        <span>{{ oldUserInfo.userCompany }}</span>
                    </div>
                </div>
                <div class="mima">
                    <span>密码信息</span>
                </div>
                <el-form
                    ref="loginFormRef1"
                    :inline="true"
                    label-position="left"
                    label-width="90px"
                    :model="formData"
                    :rules="loginFormRules"
                    class="formc"
                >
                    <el-form-item label="旧密码" prop="oldpassword">
                        <el-input
                            size="small"
                            v-model="formData.oldpassword"
                            type="password"
                            show-password
                            style="width: 260px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="password">
                        <el-input
                            size="small"
                            v-model="formData.password"
                            style="width: 260px"
                            type="password"
                            show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="password2">
                        <el-input
                            size="small"
                            v-model="formData.password2"
                            type="password"
                            show-password
                            style="width: 260px"
                        ></el-input>
                    </el-form-item>
                    <el-row type="flex" justify="between">
                        <el-link type="primary" @click="forgetPwd">
                            忘记密码
                        </el-link>
                        <el-button
                            size="small"
                            type="primary"
                            @click="updatePassword"
                            style="margin-left: 240px"
                        >
                            保存
                        </el-button>
                    </el-row>
                </el-form>
            </el-card>
        </div>

        <editTel-dialog
            v-if="editTelDialogVisible"
            ref="EditTelDialog"
            @refreshDataList="refleshInfo"
        ></editTel-dialog>
        <!-- 忘记密码 -->
        <forgetPwd-dialog
            v-if="dialogVisible"
            ref="ForgetPwdDialog"
            :disabled="true"
        ></forgetPwd-dialog>
    </div>
</template>

<script>
import { phoneReg } from "@/utils/config";
import EditTelDialog from "./components/editTel";
import ForgetPwdDialog from "./components/forgetPwd";
import { updateUserInfo, userUpdatePwd, userUpdateQQEmail } from "@/api";
import md5 from "js-md5";
import { apiCallback } from "@/utils";
export default {
    components: { EditTelDialog, ForgetPwdDialog },
    data() {
        var validatePass = (rule, value, callback) => {
            var passwordreg = new RegExp(
                "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}",
            );
            var isValid = passwordreg.test(value);
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value.length > 16 || value.length < 8) {
                callback(new Error("密码必须8~16位之间"));
            } else if (isValid != true) {
                callback(new Error("密码必须包含大小写字母和数字"));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.formData.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            editTelDialogVisible: false,
            dialogVisible: false,
            id: 0,
            changeQQFlag: 0,
            changeNameFlag: 0,
            newQQNum: "",
            newName: "",
            oldUserInfo: {
                userName: "",
                userTel: "",
                qqNumber: "",
                userCompany: "",
            },
            newUserInfo: {
                userTel: "",
            },
            formData: {
                userTel: "",
                oldpassword: "",
                password: "",
                password2: "",
            },
            // 这是表单的验证规则对象
            loginFormRules: {
                // 验证用户名是否合法
                username: [
                    {
                        required: true,
                        message: "请输入用户名称",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 10,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur",
                    },
                ],
                // 验证密码是否合法
                oldpassword: [{ validator: validatePass, trigger: "blur" }],
                password: [{ validator: validatePass, trigger: "blur" }],
                password2: [{ validator: validatePass2, trigger: "blur" }],
                userTel: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
                companyName: [
                    {
                        required: true,
                        message: "请输入公司名称",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        max: 10,
                        message: "长度在 2 到 10 个字符",
                        trigger: "blur",
                    },
                ],
                duty: [
                    { required: true, message: "请输入职务", trigger: "blur" },
                    {
                        min: 2,
                        max: 10,
                        message: "长度在 2 到 10 个字符",
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    mounted() {
        window.sessionStorage.setItem("activePath", "/user/acset");
        this.changeQQFlag = 0;
        if (this.$store.state.user.userId) {
            this.id = this.$store.state.user.userId;
            this.oldUserInfo.userName = this.$store.state.user.username;
            this.oldUserInfo.userTel = this.$store.state.user.userphone;
            this.oldUserInfo.qqNumber = this.$store.state.user.qqNumber;
            this.oldUserInfo.userCompany = this.$store.state.user.loginId;
        }
    },

    methods: {
        toggleItemFlag(type, showFlag) {
            if (type === "userName") {
                if (!showFlag) {
                    this.changeNameFlag = 1;
                    this.newName = this.oldUserInfo.userName;
                    this.$nextTick(function () {
                        this.$refs.userNameInput.focus();
                    });
                } else {
                    this.changeNameFlag = 0;
                }
            } else if (type === "qqNumber") {
                if (!showFlag) {
                    this.changeQQFlag = 1;
                    this.newQQNum = this.oldUserInfo.qqNumber;
                    this.$nextTick(function () {
                        this.$refs.qqNumInput.focus();
                    });
                } else {
                    this.changeQQFlag = 0;
                }
            }
        },
        refleshInfo() {
            this.oldUserInfo.userTel = this.$store.state.user.userphone;
            // this.oldUserInfo.userCompany = this.$store.state.user.loginId
        },
        editInfo(tel) {
            // console.log(index)
            this.editTelDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.EditTelDialog.init(tel, this.id);
            });
        },
        editQQNumber() {
            if (this.newQQNum == "") {
                this.changeQQFlag = 0;
                return;
            }
            if (this.newQQNum == this.oldUserInfo.qqNumber) {
                this.$message.error("请输入新的QQ号");
                return;
            }
            let codeReg = new RegExp("[0-9]"); //正则 数字
            if (!codeReg.test(this.newQQNum)) {
                this.$message.error("请输入正确的QQ号");
                return;
            }
            var that = this;
            userUpdateQQEmail({ qqNumber: this.newQQNum })
                .then(async (res) => {
                    that.$message.success("修改成功");
                    that.oldUserInfo.qqNumber = that.newQQNum;
                    that.$store.commit("user/editQQNumber", that.newQQNum);
                    that.newQQNum = "";
                    that.changeQQFlag = 0;
                })
                .catch((err) => console.error(err));
        },
        changeName() {
            if (this.newName) {
                this.updateUserInfo(this.newName);
                this.newName = "";
            }
            this.changeNameFlag = 0;
        },
        //用户基本信息修改
        async updateUserInfo(name) {
            var that = this;
            await updateUserInfo({ name })
                .then(async (res) => {
                    that.$message.success("修改成功");
                    that.oldUserInfo.userName = name;
                    that.$store.commit("user/editUsername", name);
                })
                .catch((err) => console.error(err));
        },
        updatePassword() {
            var that = this;
            var data = {};
            data.id = this.id;
            data.oldpassword = md5(this.formData.oldpassword);
            data.newpassword = md5(this.formData.password);
            this.$refs.loginFormRef1.validate(async (valid) => {
                if (valid) {
                    userUpdatePwd(data)
                        .then(async (res) => {
                            const { message } = apiCallback(res, false);
                            that.$message.success(message);
                        })
                        .catch((err) => console.error(err));
                }
            });
        },
        forgetPwd() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.ForgetPwdDialog.init(
                    this.oldUserInfo.userTel,
                    this.id,
                );
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
/deep/input::-webkit-input-placeholder {
  color: #778899;
}

/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
    padding: 1.68rem;
}
.header {
    color: white;
    font-size: 1.68rem;
    font-weight: 700;
}

.el-breadcrumb {
  margin-bottom: 15px;
  /* margin-right: 150px; */
}

.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.box-card {
  width: 800px;
}

.bac {
  background-color: aliceblue;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bac_f {
  background-color: aliceblue;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formc {
  display: flex;
  flex-direction: column;
}

.clearfix {
  font-size: 26px;
  font-weight: 600;
}

.mima {
  font-size: 18px;
  font-weight: 550;
  margin-bottom: 10px;
}

.text_item_wrap {
  font-size: 14px;
  /* margin-top: 10px; */
  margin-bottom: 20px;
  .text_item{
    height:40px;
    display: flex;
    align-items: center;
    margin-bottom:10px;
  }
  .text_item_label{
    width: 80px;
    padding-right:10px;
  }
}

.el-icon-edit:before {
  content: '\e78c';
  margin-left: 10px;
  cursor: pointer;
}
</style>
